import { getHoomanDate } from "@/flux/documents.flux";
import { isMultiCustomerBackopEmailUnformator } from "@/Plugins/utils.js";

var location="";
var ip="";
var deviceInfo="";
var employeeName="";
var employeeCode="";
var employeeEmail="";
var cafNumber= "";
var canvasWidth="";
var resettingStreams=false;
var remoteVideoMuted=false;
var latLongString="";

function MultiStreamRecorder(info, arrayOfMediaStreams, options, recordingErrorCB, shouldCheckBlackScreen) {
    arrayOfMediaStreams = arrayOfMediaStreams || [];
    location = info.location;
    latLongString = info?.latLongString;
    ip=info.ip;
    deviceInfo=info.deviceInfo;
    employeeName=info.employeeName;
    employeeCode=info.employeeCode;
    employeeEmail=info.employeeEmail;
    cafNumber=info.cafNumber;


    if (arrayOfMediaStreams instanceof MediaStream) {
        arrayOfMediaStreams = [arrayOfMediaStreams];
    }

    var self = this;

    var mixer;
    var mediaRecorder;
    var chuckReader = null

    options = options || {
        mimeType: 'video/webm;codecs=vp8',
        video: {
            width: 600,
            height: 500
        }
    };

    if (!options.frameInterval) {
        options.frameInterval = 10;
    }

    if (!options.video) {
        options.video = {};
    }

    if (!options.video.width) {
        options.video.width = 600;
    }

    if (!options.video.height) {
        options.video.height = 500;
    }

    this.start = function (timeSlice, cb) {
        // github/muaz-khan/MultiStreamsMixer
        mixer = new MultiStreamsMixer(arrayOfMediaStreams, recordingErrorCB, shouldCheckBlackScreen);

        if (getVideoTracks().length) {
            mixer.frameInterval = options.frameInterval || 10;
            mixer.width = options.video.width || 600;
            mixer.height = options.video.height || 500;
            mixer.startDrawingFrames();
        }

        if (typeof self.previewStream === 'function') {
            self.previewStream(mixer.getMixedStream());
        }

        // record using MediaRecorder API
        mediaRecorder = new MediaRecorder(mixer.getMixedStream(), { type: options.mimeType });
        console.log("MediaRecorder initialized using this codec: ", options.mimeType)

        mediaRecorder.addEventListener('dataavailable', event => {
            cb(event);
        })

        if (mediaRecorder.state != 'recording') {
            mediaRecorder.start();
            chuckReader = setInterval(() => {
                mediaRecorder.requestData()
            }, timeSlice);
        }

    };

    function getVideoTracks() {
        var tracks = [];
        arrayOfMediaStreams.forEach(function (stream) {
            stream.getVideoTracks().forEach(function (track) {
                tracks.push(track);
            });
        });
        return tracks;
    }

    this.stop = function () {
        try {
            clearInterval(chuckReader);
            if (!mediaRecorder) {
                return;
            }
            if (mediaRecorder.state != 'inactive') {
                mediaRecorder.stop();
                mixer.releaseStreams();
                //removing canvas
                document.getElementsByTagName("canvas")[0].parentNode.removeChild(document.getElementsByTagName("canvas")[0]);
            }
        } catch (error) {
            console.log("Error occured while stoping", error);
        }

    };

    this.pause = function () {
        if (mediaRecorder) {
            mediaRecorder.pause();
            resettingStreams = true;
        }
    };

    this.resume = function () {
        if (mediaRecorder) {
            mediaRecorder.resume();
            resettingStreams = false;
        }
    };

    this.clearRecordedData = function () {
        if (mediaRecorder) {
            mediaRecorder.clearRecordedData();
            mediaRecorder = null;
        }

        if (mixer) {
            mixer.releaseStreams();
            mixer = null;
        }
    };

    this.addStreams = this.addStream = function (streams) {
        if (!streams) {
            throw 'First parameter is required.';
        }

        if (!(streams instanceof Array)) {
            streams = [streams];
        }

        arrayOfMediaStreams.concat(streams);

        if (!mediaRecorder || !mixer) {
            return;
        }

        mixer.appendStreams(streams);
    };

    this.resetVideoStreams = function (streams) {
        if (!mixer) {
            return;
        }

        if (streams && !(streams instanceof Array)) {
            streams = [streams];
        }

        mixer.resetVideoStreams(streams);
    };

    this.ondataavailable = function (blob) {
        if (self.disableLogs) {
            return;
        }

        console.log('ondataavailable', blob);
    };

    this.onstop = function () { };

    // for debugging
    this.name = 'MultiStreamRecorder';
    this.toString = function () {
        return this.name;
    };

    this.remoteVideoMuted = (muted) => {
        remoteVideoMuted = muted;
    }
}

function MultiStreamsMixer(arrayOfMediaStreams, recordingErrorCB, shouldCheckBlackScreen) {

    // requires: chrome://flags/#enable-experimental-web-platform-features

    var videos = [];
    var isStopDrawingFrames = false;

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.style = 'display:none;opacity:0;position:absolute;z-index:-1;top: -100000000;left:-1000000000; margin-top:-1000000000;margin-left:-1000000000;';
    (document.body || document.documentElement).appendChild(canvas);

    this.disableLogs = false;
    this.frameInterval = 10;

    this.width = 600;
    this.height = 500;

    // use gain node to prevent echo
    this.useGainNode = true;

    var self = this;

    // _____________________________
    // Cross-Browser-Declarations.js

    // WebAudio API representer
    var AudioContext = window.AudioContext;

    if (typeof AudioContext === 'undefined') {
        if (typeof webkitAudioContext !== 'undefined') {
            /*global AudioContext:true */
            AudioContext = webkitAudioContext;
        }

        if (typeof mozAudioContext !== 'undefined') {
            /*global AudioContext:true */
            AudioContext = mozAudioContext;
        }
    }

    /*jshint -W079 */
    var URL = window.URL;

    if (typeof URL === 'undefined' && typeof webkitURL !== 'undefined') {
        /*global URL:true */
        URL = webkitURL;
    }

    if (typeof navigator !== 'undefined' && typeof navigator.getUserMedia === 'undefined') { // maybe window.navigator?
        if (typeof navigator.webkitGetUserMedia !== 'undefined') {
            navigator.getUserMedia = navigator.webkitGetUserMedia;
        }

        if (typeof navigator.mozGetUserMedia !== 'undefined') {
            navigator.getUserMedia = navigator.mozGetUserMedia;
        }
    }

    var MediaStream = window.MediaStream;

    if (typeof MediaStream === 'undefined' && typeof webkitMediaStream !== 'undefined') {
        MediaStream = webkitMediaStream;
    }

    /*global MediaStream:true */
    if (typeof MediaStream !== 'undefined') {
        if (!('getVideoTracks' in MediaStream.prototype)) {
            MediaStream.prototype.getVideoTracks = function () {
                if (!this.getTracks) {
                    return [];
                }

                var tracks = [];
                this.getTracks.forEach(function (track) {
                    if (track.kind.toString().indexOf('video') !== -1) {
                        tracks.push(track);
                    }
                });
                return tracks;
            };

            MediaStream.prototype.getAudioTracks = function () {
                if (!this.getTracks) {
                    return [];
                }

                var tracks = [];
                this.getTracks.forEach(function (track) {
                    if (track.kind.toString().indexOf('audio') !== -1) {
                        tracks.push(track);
                    }
                });
                return tracks;
            };
        }

        // override "stop" method for all browsers
        if (typeof MediaStream.prototype.stop === 'undefined') {
            MediaStream.prototype.stop = function () {
                this.getTracks().forEach(function (track) {
                    track.stop();
                });
            };
        }
    }

    var Storage = {};

    if (typeof AudioContext !== 'undefined') {
        Storage.AudioContext = AudioContext;
    } else if (typeof webkitAudioContext !== 'undefined') {
        Storage.AudioContext = webkitAudioContext;
    }

    this.startDrawingFrames = function () {
        drawVideosToCanvas();
    };

    function isFrameBlack(video, brightnessThreshold = 20, darkPixelRatio = 0.8) {
        try {
            if (!video || !(video instanceof HTMLVideoElement)) {
                console.log("Invalid video element provided for black frame detection");
                return false;
            }
            // Create a canvas to draw the video frame
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            let blockSize = 5, // Only visiting every 5 pixels
                data, width, height,
                darkPixelCount = 0,
                totalPixelCount = 0;
    
            if (!context) {
                console.log("Unable to process canvas");
                return false;
            }
    
            // Set canvas dimensions to match the video dimensions
            height = canvas.height = video.naturalHeight || video.offsetHeight || video.height;
            width = canvas.width = video.naturalWidth || video.offsetWidth || video.width;
    
            // Draw the current video frame onto the canvas
            context.drawImage(video, 0, 0, width, height);
    
            if (width === 0 || height === 0) {
                console.log("Canvas size zero, returning false for black frame check");
                return false;
            }
    
            // Get pixel data from the canvas
            data = context.getImageData(0, 0, width, height).data;
    
            for (let i = 0; i < data.length; i += blockSize * 4) {
                totalPixelCount++;
                const r = data[i];
                const g = data[i + 1];
                const b = data[i + 2];
    
                // Calculate brightness as a simple average of RGB values
                const brightness = (r + g + b) / 3;
    
                // Count as dark if brightness is below the threshold
                if (brightness < brightnessThreshold) {
                    darkPixelCount++;
                }
            }
    
            // Calculate the ratio of dark pixels to total pixels
            const darkRatio = darkPixelCount / totalPixelCount;
    
            // Return true if the dark pixel ratio exceeds the specified limit
            return darkRatio >= darkPixelRatio;
        } catch (err) {
            console.log("Error in black frame detection:", err);
            return false;
        }
    }

    let blackFrameStartTimeEndUser = null;
    let blackFrameStartTimeRE = null;
    
    function drawVideosToCanvas() {
        if (isStopDrawingFrames) {
            return;
        }

        var videosLength = videos.length;

        var fullcanvas = false;
        var remaining = [];
        videos.forEach(function (video, index) {
            if (!video.stream) {
                video.stream = {};
            }

            if (video.stream.fullcanvas) {
                fullcanvas = video;
            } else {
                remaining.push(video);
            }

            let isEndUserStream = index == 1;

            if(shouldCheckBlackScreen) {
                if (isEndUserStream && !remoteVideoMuted && isFrameBlack(video) && !resettingStreams) {
                    if (!blackFrameStartTimeEndUser) {
                        blackFrameStartTimeEndUser = Date.now();
                    } else {
                        let duration = (Date.now() - blackFrameStartTimeEndUser) / 1000;
                        if (parseInt(duration)>= 10) {
                            recordingErrorCB();
                        }
                    }
                } else if (isEndUserStream && !isFrameBlack(video)) {
                    if (blackFrameStartTimeEndUser) {
                        let duration = (Date.now() - blackFrameStartTimeEndUser) / 1000;
                        console.log(`End user stream had black frames for ${duration} seconds.`);
                        blackFrameStartTimeEndUser = null;
                    }
                }
        
                // Handle RE Stream
                if (!isEndUserStream && video.stream?.active && isFrameBlack(video) && !resettingStreams) {
                    if (!blackFrameStartTimeRE) {
                        blackFrameStartTimeRE = Date.now();
                    } else {
                        let duration = (Date.now() - blackFrameStartTimeRE) / 1000; // Convert to seconds
                        if (parseInt(duration) >= 10) {
                            recordingErrorCB();
                        }
                    }
                } else if (!isEndUserStream && !isFrameBlack(video)) {
                    if (blackFrameStartTimeRE) {
                        let duration = (Date.now() - blackFrameStartTimeRE) / 1000;
                        console.log(`RE stream had black frames for ${duration} seconds.`);
                        blackFrameStartTimeRE = null;
                    }
                }
            }
        });

        if (fullcanvas) {
            canvas.width = fullcanvas.stream.width;
            canvas.height = fullcanvas.stream.height;
        } else if (remaining.length) {
            canvas.width = 200 + remaining[0].width;
            //canvas.width = videosLength > 1 ? remaining[0].width * 2 : remaining[0].width;
            canvas.height = videosLength > 2 ? remaining[0].height * 2 : remaining[0].height;
        } else {
            canvas.width = self.width || 600;
            canvas.height = self.height || 420;
        }
        canvasWidth =canvas.width;
        canvas.height+=80;
        if (fullcanvas && fullcanvas instanceof HTMLVideoElement) {
            drawImage(fullcanvas);
        }

        remaining.forEach(function (video, idx) {
            drawImage(video, idx);
        });

        setTimeout(drawVideosToCanvas, self.frameInterval);
    }

    function drawImage(video, idx) {
        if (isStopDrawingFrames) {
            return;
        }

        /**
         * Refer: VKYC-3798
         * Handling case when there's only stream and that too
         * of End User, that means RE has disabled his/her camera.
         */

        let reCameraDisabled = videos.length === 1 && 
            (
                video?.stream?.id?.toString()?.includes('video') //for jitsi
                ||
                video?.stream?.id?.toString()?.includes('v_') //for chime
            )

        if (reCameraDisabled) idx = 1;

        var x = 0;
        var y = 0;
        var width = video.width;
        var height = video.height;
        
        if (idx == 0) {
            width = 200;
            height = 120;
        }

        if (idx === 1) {
            //x = video.width;
            x = 200;
        }

        if (idx === 2) {
            y = video.height;
        }

        if (idx === 3) {
            x = video.width;
            y = video.height;
        }

        if (typeof video.stream.left !== 'undefined') {
            x = video.stream.left;
        }

        if (typeof video.stream.top !== 'undefined') {
            y = video.stream.top;
        }

        if (typeof video.stream.width !== 'undefined') {
            width = video.stream.width;
        }

        if (typeof video.stream.height !== 'undefined') {
            height = video.stream.height;
        }
        
        context.drawImage(video, x, y, width, height);
        if (idx > 0) {

            context.fillStyle = "white";
            context.fillRect(0, height, canvasWidth, 80);
            context.font = "12px Arial";
            context.fillStyle = "black";
            context.textAlign = "left";
            var paddingTop = height +15;
            if(employeeName)
            {context.fillText(`Employee name:${employeeName}`, 10, paddingTop);
            paddingTop+=15;}
            if(employeeCode)
            {context.fillText(`Employee code: ${employeeCode}`, 10, paddingTop);
            paddingTop+=15;}
            if(employeeEmail)
            {
                if(employeeEmail.includes('+')){
                    employeeEmail = isMultiCustomerBackopEmailUnformator(employeeEmail)
                }
                context.fillText(`Employee email:${employeeEmail}`, 10, paddingTop);
            }
            context.textAlign = "right";
            let date = new Date();
            let x = date.toLocaleString();
            paddingTop = height+15;
            context.fillText(`Recording date & time: ${x}`, canvasWidth - 10, paddingTop);
            paddingTop+=15;
            context.fillText(`CAF Number: ${cafNumber}`, canvasWidth - 10, paddingTop);
            paddingTop+=15;
            if(ip){
                context.fillText(`IP Address: ${ip}`, canvasWidth - 10, paddingTop);
                paddingTop+=15;
            }
            if(location){
                context.fillText(`Location: ${location}`, canvasWidth - 10, paddingTop);
                paddingTop+=15;
            }
            if(latLongString){
                context.fillText(`Coordinates: ${latLongString}`, canvasWidth - 10, paddingTop);
                paddingTop+=15;
            }
            if(deviceInfo){
                context.fillText(`Device info: ${deviceInfo}`, canvasWidth-10, paddingTop);
            }
        }

        if (typeof video.stream.onRender === 'function') {
            video.stream.onRender(context, x, y, width, height, idx);
        }
    }

    function getMixedStream() {
        isStopDrawingFrames = false;
        var mixedVideoStream = getMixedVideoStream();

        var mixedAudioStream = getMixedAudioStream();
        if (mixedAudioStream) {
            mixedAudioStream.getAudioTracks().forEach(function (track) {
                mixedVideoStream.addTrack(track);
            });
        }

        var fullcanvas;
        arrayOfMediaStreams.forEach(function (stream) {
            if (stream.fullcanvas) {
                fullcanvas = true;
            }
        });

        return mixedVideoStream;
    }

    function getMixedVideoStream() {
        resetVideoStreams();

        var capturedStream;

        if ('captureStream' in canvas) {
            capturedStream = canvas.captureStream();
        } else if ('mozCaptureStream' in canvas) {
            capturedStream = canvas.mozCaptureStream();
        } else if (!self.disableLogs) {
            console.error('Upgrade to latest Chrome or otherwise enable this flag: chrome://flags/#enable-experimental-web-platform-features');
        }

        var videoStream = new MediaStream();

        capturedStream.getVideoTracks().forEach(function (track) {
            videoStream.addTrack(track);
        });

        canvas.stream = videoStream;

        return videoStream;
    }

    function getMixedAudioStream() {
        // via: @pehrsons
        if (!Storage.AudioContextConstructor) {
            Storage.AudioContextConstructor = new Storage.AudioContext();
        }

        self.audioContext = Storage.AudioContextConstructor;

        self.audioSources = [];

        if (self.useGainNode === true) {
            self.gainNode = self.audioContext.createGain();
            self.gainNode.connect(self.audioContext.destination);
            self.gainNode.gain.value = 0; // don't hear self
        }

        var audioTracksLength = 0;
        arrayOfMediaStreams.forEach(function (stream) {
            if (!stream.getAudioTracks().length) {
                return;
            }

            audioTracksLength++;

            var audioSource = self.audioContext.createMediaStreamSource(stream);

            if (self.useGainNode === true) {
                audioSource.connect(self.gainNode);
            }

            self.audioSources.push(audioSource);
        });

        if (!audioTracksLength) {
            return;
        }

        self.audioDestination = self.audioContext.createMediaStreamDestination();
        self.audioSources.forEach(function (audioSource) {
            audioSource.connect(self.audioDestination);
        });
        return self.audioDestination.stream;
    }

    function getVideo(stream) {
        var video = document.createElement('video');

        if ('srcObject' in video) {
            video.srcObject = stream;
        } else {
            video.src = URL.createObjectURL(stream);
        }

        video.muted = true;
        video.volume = 0;

        video.width = stream.width || self.width || 360;
        video.height = stream.height || self.height || 240;

        video.play();

        return video;
    }

    this.appendStreams = function (streams) {
        if (!streams) {
            throw 'First parameter is required.';
        }

        if (!(streams instanceof Array)) {
            streams = [streams];
        }

        arrayOfMediaStreams.concat(streams);

        streams.forEach(function (stream) {
            if (stream.getVideoTracks().length) {
                var video = getVideo(stream);
                video.stream = stream;
                videos.push(video);
            }

            if (stream.getAudioTracks().length && self.audioContext) {
                var audioSource = self.audioContext.createMediaStreamSource(stream);
                audioSource.connect(self.audioDestination);
                self.audioSources.push(audioSource);
            }
        });
    };

    this.releaseStreams = function () {
        resettingStreams = true;
        videos = [];
        isStopDrawingFrames = true;

        if (self.gainNode) {
            self.gainNode.disconnect();
            self.gainNode = null;
        }

        if (self.audioSources.length) {
            self.audioSources.forEach(function (source) {
                source.disconnect();
            });
            self.audioSources = [];
        }

        if (self.audioDestination) {
            self.audioDestination.disconnect();
            self.audioDestination = null;
        }

        self.audioContext = null;

        context.clearRect(0, 0, canvas.width, canvas.height);

        if (canvas.stream) {
            canvas.stream.stop();
            canvas.stream = null;
        }
    };

    this.resetVideoStreams = function (streams) {
        if (streams && !(streams instanceof Array)) {
            streams = [streams];
        }

        resetVideoStreams(streams);
    };

    function resetVideoStreams(streams) {
        resettingStreams = true;
        videos = [];
        streams = streams || arrayOfMediaStreams;

        // via: @adrian-ber
        streams.forEach(function (stream) {
            if (stream.getTracks().filter((track) => {
                return track.kind === 'video';
            }).length) {
                var video = getVideo(stream);
                video.stream = stream;
                videos.push(video);
            }
            // Create a media stream using audio Context in order to
            // capture audio tracks in case of any changes in stream.
            if (stream.getTracks().filter((track) => {
                return track.kind === 'audio';
            }).length && self.audioContext) {
                var audioSource = self.audioContext.createMediaStreamSource(stream);
                audioSource.connect(self.audioDestination);
                self.audioSources.push(audioSource);
            }
        })
        resettingStreams = false;
    }

    // for debugging
    this.name = 'MultiStreamsMixer';
    this.toString = function () {
        return this.name;
    };

    this.getMixedStream = getMixedStream;

}

export {
    MultiStreamRecorder
}